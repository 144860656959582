@include media(desktop) {
  .header__top .navbar .navbar-nav {
    flex-direction: row;
    display: flex;
    position: relative;
    transform: none;
    align-items: center;
    min-height: auto;
  }

  .header__top .navbar .custom-nav {
    min-height: auto;
    transform: none;
    position: relative;
    background-color: transparent;
    box-shadow: none;
    padding: 0;

  }

  button.hamburger {
    display: none;
  }

  .header__top .navbar .navbar-nav {
    position: relative;
  }

  body:not(.scrolled) {

    .header__top .navbar .navbar-nav>li>a {
      color: var(--color-light);
    }
  }


  .client.section .client-wrapper .client-content-inner {
    margin-bottom: -200px;
  }

  .video {
    height: 60vh;
  }

  .header__top .navbar .navbar-nav li.has-child {
    &:hover {
      .sub-menu {
        opacity: 1;
        visibility: visible;
        top: 100%;
        margin-top: 10px;
      }
    }
  }

  .header__top .navbar .navbar-nav li .sub-menu {
    position: absolute;
    top: 140%;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;

    li {
      text-align: left;
    }
  }

  .todo-result {
    display: grid;
    margin-top: 30px;
    grid-template-columns: repeat(3, 1fr);
  }

  .weather__forecast ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-top: 3rem;
  }

  .shop__wrapper {
    grid-template-columns: repeat(4, 1fr);
  }


  .cart-wrapper {
    grid-template-columns: 70% 1fr
  }

  .faq .inner-container .skills {
    grid-template-columns: repeat(3, 1fr);
  }
}