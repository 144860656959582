@include media(tablet) {
  section {
    padding: 40px 0;
  }

  .stats {
    margin-top: 0;
  }

  .container,
  .header__top {
    padding-left: 50px;
    padding-right: 50px;
  }

  .client.section .client-wrapper .client-content-inner {
    margin-bottom: -140px;
  }

  .contact .input-row {
    grid-gap: 40px;
  }

  .weather__forecast ul {
    grid-template-columns: repeat(2, 1fr);
  }

  .tab .tabs {
    grid-template-columns: 20% 80%;

    ul {
      flex-direction: column;
      margin: 0;
      justify-content: flex-start;
    }
  }

  .tab__lists li.active-tab:after {
    position: absolute;
    right: -21px;
    top: 0;
    width: 20px;
    height: 100%;
    content: "";
  }

  .single__content .title {
    font-size: 2rem;
  }

  .trending {
    .card {
      @include transition(0.5s ease-in-out);

      &:hover,
      &:focus {
        @include transform(scale(1.3))
      }
    }
  }

  .trending .swiper {
    padding: 3rem 2rem;
  }

  .shop__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .single-product {
    grid-template-columns: repeat(2, 1fr);
    gap: 3.5rem;
  }

  .faq .inner-container {
    grid-template-columns: repeat(2, 1fr);

    .skills {
      grid-template-columns: repeat(2, 1fr);
    }
  }


}