a {
  color: var(--dark-color);
}


.faq .highlight {
  color: var(--primary-color);
}

.client-content-inner {
  background-color: var(--background-primary-color);
}

// button background COlor

.avatar,
.faq .highlight {
  background-color: var(--background-hover-button);
}

.scroll-up:hover {
  color: var(--background-hover-button);
}