@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primary-color: #fff;
  --secondary-color: #06b7ff;
  --black-color: #000;
  --dark-color: #333;
  --color-light: #fff;
  --btn-background: #fff;
  --shadow: rgba(0, 0, 0, 0.2);
  --background-primary-color: #fff;
  --background-secondary-color: #f8f8f8;
  --background-dark-color: #333;
  --background-button: #000;
  --button-color: #fff;
  --background-hover-button: #05a394;
  --border-color: #e6e6e6;
}

.dark {
  --primary-color: #fff;
  --secondary-color: #06b7ff;
  --color-light: #000;
  --black-color: #fff;
  --dark-color: #fff;
  --btn-background: #3d3d3d;
  --background-primary-color: #000;
  --background-secondary-color: #141414;
  --background-dark-color: #020202;
  --background-button: #fff;
  --button-color: #000;
  --background-hover-button: #05a394;
  --border-color: #2e2d2d;
}

* {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  background-color: var(--background-primary-color);
  color: var(--dark-color);
}

.entry-content {
  margin-bottom: 21px;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.container,
.header__top {
  padding-left: 30px;
  padding-right: 30px;
}

button,
input[type=submit] {
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #05a394;
}

ul {
  list-style-type: none;
  padding: 0;
}

.section:nth-child(odd) {
  background-color: var(--background-secondary-color);
}

.btn__link,
.caption {
  transition: 0.3s ease-in-out;
}

.section__heading {
  padding-bottom: 28px;
}
.section__heading h2 {
  font-size: clamp(42px, 3.5vw, 65px);
}

.stats {
  margin-top: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Ibarra Real Nova", serif;
  color: var(--dark-color);
  margin-top: 0;
  font-weight: 700;
}

.faq .section__heading {
  text-align: left;
}

.cursor {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 76px;
  height: 76px;
  z-index: 999999;
  pointer-events: none;
  border-radius: 50%;
  transform-origin: left top;
  transition: 0.2s;
  transform: scale(0.35) translate(-50%, -50%);
  background-color: #fff;
  mix-blend-mode: difference;
}

.input-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.form form {
  max-width: 850px;
  margin: 0 auto;
}
.form form .input-wrap:not(:last-child) {
  margin-bottom: 30px;
}

input,
textarea {
  padding: clamp(10px, 3.5vw, 15px) clamp(21px, 3.5vw, 45px);
  color: var(--dark-color);
  outline: none;
}

.time.btn__link {
  margin-top: 0;
  text-align: center;
  border-radius: 0;
  padding: 2px 14px;
  min-width: 100px;
}

.scroll-up {
  position: fixed;
  right: 50px;
  cursor: pointer;
  bottom: 50px;
  transform: scale(4);
  z-index: 99999;
  opacity: 0;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.scroll-up:hover {
  -moz-transform: scale(2.5) !important;
  -o-transform: scale(2.5) !important;
  -webkit-transform: scale(2.5) !important;
  transform: scale(2.5) !important;
}

.input-group {
  position: relative;
}
.input-group .error {
  color: red;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
}

.search-input {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 40px;
}
.search-input input {
  padding: 15px;
  width: 100%;
}

.toggle {
  position: relative;
  box-sizing: border-box;
  width: 75px;
  margin: 0 auto;
  border: 1px solid #fff;
  border-radius: 25px;
  height: 40px;
  overflow: hidden;
}
.toggle input[type=checkbox] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  background-color: #fff;
}
.toggle input[type=checkbox]:checked {
  background-color: #000;
}
.toggle input[type=checkbox]:checked + label {
  background-color: #fff;
}
.toggle input[type=checkbox]:checked + label:before {
  background: url(../assets/images/moon.png) no-repeat center center;
  background-size: contain;
  -moz-transform: translateX(130%);
  -o-transform: translateX(130%);
  -webkit-transform: translateX(130%);
  transform: translateX(130%);
}
.toggle label {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.toggle label:before {
  background: url(../assets/images/sun.png) no-repeat center center;
  content: "";
  width: 30px;
  left: 0;
  height: 30px;
  position: absolute;
  display: inline-block;
  border-radius: 20px;
  box-sizing: border-box;
  transition: 0.25s ease-in-out;
  background-size: contain;
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.header__top .navbar {
  left: 0;
  width: 100%;
  z-index: 1;
}
.header__top .navbar h1 {
  font-size: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  position: relative;
  z-index: 9999;
}
.header__top .navbar h1 .logo {
  max-width: 40px;
}
.header__top .navbar .navbar-nav {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.header__top .navbar .navbar-nav li {
  position: relative;
}
.header__top .navbar .navbar-nav li .sub-menu {
  top: 100%;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  left: 0;
  width: 100%;
  background-color: var(--background-secondary-color);
  display: none;
}
.header__top .navbar .navbar-nav li .sub-menu li {
  padding: 15px;
  text-align: center;
}
.header__top .navbar .navbar-nav li .sub-menu li:not(:last-child) {
  border-bottom: 1px solid rgba(169, 169, 169, 0.3);
}
.header__top .navbar .navbar-nav a {
  font-size: 14px;
  color: var(--dark-color);
  text-transform: uppercase;
  position: relative;
  transition: 0.5s ease-in-out;
  padding: 10px 5px;
}
.header__top .navbar .navbar-nav a.active {
  color: var(--background-hover-button);
}
.header__top .navbar .navbar-nav a:hover, .header__top .navbar .navbar-nav a:focus {
  color: var(--background-hover-button);
}

body:not(.scrolled) .header__top .navbar .navbar-nav > li > a,
body:not(.scrolled) .navbar:has(.custom-nav.active) .hamburger {
  color: var(--dark-color);
}

body:not(.scrolled) .site-branding a,
body:not(.scrolled) .hamburger {
  color: var(--color-light);
}

body.scrolled .site-branding a {
  color: var(--dark-color);
}
body.scrolled.dark .site-branding a {
  color: var(--dark-color);
}

body.dark:not(.scrolled) .header__top .navbar .navbar-nav > li > a,
body.dark:not(.scrolled) .site-branding a {
  color: var(--dark-color);
}

.site-branding {
  z-index: 0;
}

.custom-nav {
  display: flex;
  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  padding: 30px;
  padding-bottom: 50px;
  padding-top: 50px;
  background: var(--background-secondary-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.5s ease-in-out;
  transform: translateX(-100%) translateY(0);
}
.custom-nav.active {
  transform: translateX(0) translateY(0);
}

header {
  border-bottom: 1px solid var(--border-color);
  padding: 20px 0;
  -moz-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.scrolled header {
  background-color: var(--background-primary-color);
}

.header__top .navbar .navbar-nav {
  width: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  gap: 30px;
  min-height: 80vh;
  overflow: visible;
  scrollbar-width: none;
}

.navbar .inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hamburger {
  display: block;
}

button.hamburger {
  background: transparent;
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  z-index: 9999;
}
button.hamburger svg {
  font-size: 20px;
}

.header__top .navbar .navbar-nav li.has-child {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-nav ul:not(.sub-menu) li a:before {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  content: "";
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  height: 3px;
  background-color: var(--background-hover-button);
}
.custom-nav ul:not(.sub-menu) li a:hover:before {
  opacity: 1;
}
.custom-nav ul:not(.sub-menu) li a.active:before {
  opacity: 1;
}

.custom-nav .navbar-nav ul.sub-menu li a.active .custom-nav .navbar-nav ul.sub-menu li a:before {
  content: "";
}
.custom-nav .navbar-nav ul.sub-menu li a:hover::before {
  content: none;
}

.custom-nav .navbar-nav ul.sub-menu li a.active:before {
  content: none;
}

.header__top .navbar .navbar-nav li .sub-menu.show-sub {
  display: block;
  opacity: 1;
  visibility: visible;
}

.about__content {
  color: var(--primary-color);
}

.header__top .navbar .custom-nav {
  min-height: 100vh;
}

body:has(.custom-nav.active) {
  height: 100vh;
  overflow: hidden;
}

.client.section {
  background: url("../assets/images/client-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding: clamp(120px, 8vw, 450px) 0;
}
.client.section .client-wrapper {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr;
}
.client.section .client-wrapper .client-title {
  font-size: 35px;
  display: flex;
  align-items: center;
}
.client.section .client-wrapper .client-title h2 {
  color: var(--primary-color);
}
.client.section .client-wrapper .client-content-inner {
  max-width: 400px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: clamp(30px, 3vw, 40px);
  border-radius: 5px;
  margin-left: auto;
  margin-right: 0;
}
.client.section .client-wrapper .client-content-inner h2 {
  font-size: 24px;
  margin-bottom: 21px;
  font-family: "Montserrat", sans-serif;
}
.client.section .client-wrapper .client-content-inner .author {
  display: flex;
  align-items: center;
  margin-top: 35px;
  gap: 10px;
}
.client.section .client-wrapper .client-content-inner .author .avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
}

.section:nth-child(odd) .tab__lists li.active-tab,
.section:nth-child(odd) .tabs .content,
.section:nth-child(odd) .tab__lists li.active-tab:after {
  background-color: var(--background-primary-color);
}

.section:nth-child(even) .tab li.active-tab,
.section:nth-child(even) .tabs .content,
.section:nth-child(even) .tab__lists li.active-tab:after {
  background-color: var(--background-secondary-color);
}

.tab__lists {
  max-width: 500px;
  display: flex;
  gap: 20px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}
.tab__lists li {
  padding: 0.8rem 1rem;
  cursor: pointer;
  border: 1px solid #000;
  position: relative;
}
.tab__lists li.active-tab {
  border-color: transparent;
}
.tab .tabs {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
}
.tab .content {
  padding: 20px;
  background: #fff;
}
.tab .content .title {
  margin: 1rem 0;
  font-weight: 700;
}
.tab .content ul li:not(:last-child) {
  margin-bottom: 1.3rem;
}

.video {
  height: 37vh;
  position: relative;
}
.video__wrapper {
  height: 100%;
}
.video video {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.video__wrapper-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
}
.video__wrapper-overlay .button {
  font-size: 3rem;
  cursor: pointer;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100px;
}

section.movies {
  padding-top: 0;
}

.movies .banner {
  background: url("https://image.tmdb.org/t/p/w1920_and_h600_multi_faces/rGbRnRvkmrSub07ty89Vnlsh6UX.jpg") no-repeat center center;
  min-height: 250px;
  background-size: cover;
  padding: 2rem;
  position: relative;
}
.movies .banner::after {
  content: "";
  background: rgba(45, 28, 111, 0.5);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.movies .banner__content {
  color: var(--primary-color);
  max-width: 450px;
  position: relative;
  z-index: 1;
}
.movies .banner__content h2 {
  font-family: "Inter", sans-serif;
  font-size: 2.75rem;
  text-transform: uppercase;
  line-height: 1.1;
  font-weight: 700;
  display: inline-block;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}

.banner__content .btn__link {
  display: inline-block;
  border: 2px solid var(--border-color);
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -o-border-radius: 45px;
  width: 100%;
}
.banner__content .btn__link:hover, .banner__content .btn__link:focus {
  border-color: transparent;
}

.trending {
  margin-top: 3rem;
}
.trending .swiper {
  background-image: url(../assets/images/chart.svg);
  background-position: 0% 90px;
  background-repeat: no-repeat;
}
.trending .swiper .card__image {
  margin-bottom: 1rem;
}
.trending .swiper img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
}
.trending .swiper .title {
  font-weight: 700;
}
.trending .swiper .card__date {
  font-size: 0.8rem;
}

.single .banner {
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.single .banner .back {
  position: relative;
  z-index: 2;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.7rem;
}
.single .banner::after {
  content: "";
  background: rgba(45, 28, 111, 0.8);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.single__poster {
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem 0;
  position: relative;
  z-index: 3;
  grid-gap: 2rem;
}
.single__poster .poster {
  position: relative;
}
.single__poster .status {
  padding: 0.2rem 0.8rem;
  background-color: var(--background-hover-button);
  color: var(--primary-color);
  font-style: italic;
  width: auto;
  display: inline-block;
  border-radius: 45px;
  -webkit-border-radius: 45px;
  -moz-border-radius: 45px;
  -o-border-radius: 45px;
  position: absolute;
  top: 1.25rem;
  left: 1.25rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.single__poster img {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.single__content .title {
  font-size: 1.35rem;
  font-weight: 700;
}
.single__content .title h2 {
  font-family: "Inter", sans-serif;
}
.single__content .title .lang {
  text-transform: uppercase;
}
.single__content .popularity,
.single__content .duration {
  margin: 1rem 0;
  font-size: 0.8rem;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  gap: 0.5rem;
  align-items: center;
}
.single__content .tagline {
  font-style: italic;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
.single__content .overview {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.single__content .overview strong {
  margin-bottom: 0.5rem;
  display: block;
  font-size: 1.6rem;
}

.profile {
  margin: 2rem 0 3rem;
  color: var(--primary-color);
  text-align: center;
}
.profile__img {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  margin-bottom: 0.8rem;
}
.profile__img img {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.search-movie {
  margin-top: 1rem;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  position: relative;
  z-index: 1;
  max-width: 700px;
  margin: 0 auto;
  margin-top: 1rem;
}
.search-movie input {
  width: 100%;
  border-radius: 45px 0 0 45px;
  -webkit-border-radius: 45px 0 0 45px;
  -moz-border-radius: 45px 0 0 45px;
  -o-border-radius: 45px 0 0 45px;
}
.search-movie .search-btn {
  border-radius: 0 45px 45px 0;
  -webkit-border-radius: 0 45px 45px 0;
  -moz-border-radius: 0 45px 45px 0;
  -o-border-radius: 0 45px 45px 0;
  margin-top: 0;
}

.section:nth-child(odd).faq .faq__accordion h3 {
  background-color: var(--background-primary-color);
}

.section:nth-child(even).faq .faq__accordion h3 {
  background-color: var(--background-secondary-color);
}

.faq .sub-title {
  margin-bottom: clamp(21px, 3vw, 50px);
}
.faq .sub-title h2 {
  margin-bottom: 0;
}
.faq .highlight {
  padding: 0 10px;
}

.answer {
  height: 0;
  overflow: hidden;
  -moz-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
}

.answer.active {
  -moz-transition: height 0.5s ease-in-out;
  -o-transition: height 0.5s ease-in-out;
  -webkit-transition: height 0.5s ease-in-out;
  transition: height 0.5s ease-in-out;
  padding: 15px;
  height: auto;
}

.faq__accordion h3 {
  padding: 20px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq__accordion:not(:last-child) {
  margin-bottom: 20px;
}
.faq__accordion p {
  border: 1px solid var(--border-color);
}

.skills {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr;
  gap: 5px;
}
.skills li {
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  text-align: center;
  border-radius: 0.7rem;
  background: var(--dark-color);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.skills li .title,
.skills li svg {
  color: var(--color-light);
}
.skills li:hover {
  flex: 5;
  border-color: var(--border-color);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  background: var(--background-secondary-color);
}
.skills li:hover .title,
.skills li:hover svg {
  color: var(--dark-color);
}
.skills li h2 {
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
}

.faq .inner-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;
}

.portfolio .portfolio__content ul li {
  overflow: hidden;
  padding: 10px;
  position: relative;
}
.portfolio .portfolio__content ul li .caption {
  background-color: var(--background-primary-color);
  font-family: "Ibarra Real Nova", serif;
  display: block;
  font-size: 26px;
  padding: 15px;
  width: 100%;
  position: relative;
  z-index: 99;
}
.portfolio .portfolio__content ul li .project {
  display: block;
}
.portfolio .portfolio__content ul li .project:hover img {
  transform: scale(1.1);
}
.portfolio .portfolio__content ul li .project:hover .caption {
  background-color: var(--background-hover-button);
  color: var(--color-light);
}
.portfolio .portfolio__content ul li .project img {
  transition: 0.3s ease-in-out;
  width: 100%;
}

.weather .search {
  text-align: center;
  max-width: 450px;
  margin: 0 auto;
}
.weather .search input {
  width: 100%;
}
.weather__forecast .country {
  font-size: 3rem;
  text-align: center;
}
.weather__forecast .country .country-title {
  font-size: 1.5rem;
}
.weather__forecast ul {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  margin-top: 3rem;
}
.weather__forecast ul h2 {
  font-size: 3rem;
}
.weather__forecast ul li {
  background: var(--background-primary-color);
  padding: 20px;
  text-align: center;
}
.weather__forecast ul li img {
  margin: 0 auto;
}
.weather__forecast ul li .weather-data {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.snow.section {
  background: url(../assets/images/mist.jpg) no-repeat center center;
  background-size: cover;
}

.Sunny.section {
  background: url(../assets/images/sunny.jpg) no-repeat center center;
  background-size: cover;
}

.Mist.section {
  background: url(../assets/images/snow.jpg) no-repeat center center;
  background-size: cover;
}

.wind, .temp {
  background-image: url(../assets/images/wind.gif);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 50px 50px;
}

.temp {
  background-image: url(../assets/images/temp.gif);
}

.specs {
  position: relative;
}

.condition span.img {
  position: absolute;
  top: 0;
}

section.contact {
  padding-top: 0;
}

.contact input:invalid {
  background-color: ivory;
  border: 2px solid red;
  border-radius: 5px;
}
.contact .section__heading {
  text-align: left;
}
.contact .contact-form {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 30px;
}
.contact .contact-map {
  height: clamp(450px, 3vw, 500px);
}
.contact__image {
  display: flex;
  justify-content: center;
  height: 100%;
}
.contact__image img {
  width: 60%;
}
.contact__title {
  margin-bottom: 30px;
}
.contact label {
  width: 100%;
  margin-bottom: 15px;
}
.contact .input-group {
  margin-bottom: 20px;
}
.contact input,
.contact textarea {
  padding: 15px;
  width: 100%;
  border: 0;
  border: 2px solid var(--border-color);
  background-color: transparent !important;
}
.contact input:focus,
.contact textarea:focus {
  border-color: var(--background-hover-button);
  outline: none;
}
.contact textarea {
  min-height: 200px;
  margin-bottom: 0;
}
.contact .input-row {
  display: grid;
  grid-template-columns: 1fr;
}

.contact .inner-container {
  grid-template-columns: 1fr;
  display: grid;
}

.section.todo {
  background: #0cebeb;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  position: relative;
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(32, 227, 178, 0.8784313725);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  visibility: hidden;
  z-index: 99;
}
.modal.show-modal {
  opacity: 1;
  visibility: visible;
}

.todo .form-wrap {
  display: flex;
  justify-content: center;
  max-width: 500px;
  gap: 20px;
  margin: 0 auto;
  flex-wrap: wrap;
}
.todo .form-wrap select {
  padding: 10px;
  background-color: var(--background-primary-color);
}

.form-wrap form {
  display: flex;
  justify-content: center;
}
.form-wrap form input {
  padding: 10px;
  background-color: var(--background-primary-color);
}

button.btn {
  background: var(--btn-background);
  padding: 5px 15px;
  border: 1px solid transparent;
}
button.btn:hover {
  border-color: var(--btn-background);
  background-color: #20e3b2;
}

.section:nth-child(odd) input,
.section:nth-child(odd) textarea {
  background-color: var(--background-primary-color);
}

.section:nth-child(even) input,
.section:nth-child(even) textarea {
  background-color: var(--background-secondary-color);
}

.todo-result {
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}
.todo-result > div {
  margin-top: 10px;
  text-align: center;
  background: var(--background-secondary-color);
  position: relative;
  padding: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding-bottom: 50px;
}
.todo-result > div .buttons {
  position: absolute;
  right: 30px;
  bottom: 20px;
}
.todo-result > div .btn {
  margin-left: 2px;
}

.result-wrapper {
  text-align: center;
  margin-top: 30px;
}

p.input-field {
  text-align: left;
}

.completed {
  text-decoration: line-through;
  background-color: antiquewhite !important;
}

.clicked.btn {
  background-color: #20e3b2;
}

.about-section {
  position: relative;
  color: var(--black-color);
}
.about-section:after {
  content: "";
  background: rgba(77, 75, 75, 0.19);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.1px);
  -webkit-backdrop-filter: blur(12.1px);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}
.about-section video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
}
.about-section .inner_wrap {
  position: relative;
  z-index: 1;
}
.about-section h2 {
  text-shadow: 2px 2px 2px var(--shadow);
  color: #fff;
}

.marque {
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-right: 10px;
}
.marque:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-300%);
  }
}
.text-slide {
  overflow: hidden;
}

.inner-slide {
  -moz-animation: marquee 50s linear infinite;
  -webkit-animation: marquee 50s linear infinite;
  animation: marquee 50s linear infinite;
  display: flex;
}

.about-section:after,
.inner-banner:after {
  content: "";
  background: rgba(77, 75, 75, 0.19);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12.1px);
  -webkit-backdrop-filter: blur(12.1px);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.footer {
  padding: 20px 0;
  background-color: var(--background-primary-color);
}
.footer .social {
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}
.footer .social li:not(:last-child) {
  margin-right: 10px;
}
.footer .copyright-text {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}
.footer .copyright-text p {
  margin-bottom: 0;
}

.footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.footer-inner p {
  margin: 0;
}

:root {
  --primary-color: #fff;
  --secondary-color: #06b7ff;
  --black-color: #000;
  --dark-color: #333;
  --color-light: #fff;
  --btn-background: #fff;
  --shadow: rgba(0, 0, 0, 0.2);
  --background-primary-color: #fff;
  --background-secondary-color: #f8f8f8;
  --background-dark-color: #333;
  --background-button: #000;
  --button-color: #fff;
  --background-hover-button: #05a394;
  --border-color: #e6e6e6;
}

.dark {
  --primary-color: #fff;
  --secondary-color: #06b7ff;
  --color-light: #000;
  --black-color: #fff;
  --dark-color: #fff;
  --btn-background: #3d3d3d;
  --background-primary-color: #000;
  --background-secondary-color: #141414;
  --background-dark-color: #020202;
  --background-button: #fff;
  --button-color: #000;
  --background-hover-button: #05a394;
  --border-color: #2e2d2d;
}

.btn-wrap {
  margin-top: clamp(21px, 3vw, 35px);
}

.btn__link {
  background-color: var(--background-button);
  color: var(--button-color);
  border-radius: 45px;
  text-transform: uppercase;
  border: 0;
  padding: clamp(10px, 3.5vw, 15px) clamp(21px, 3.5vw, 45px);
  font-size: 0.8rem;
}
.btn__link:hover, .btn__link:focus {
  background-color: var(--background-hover-button);
  color: var(--button-color);
  box-shadow: 0 16px 29px -17px #727272;
}

.banner {
  background: url(../assets/images/shop-bg.jpg) no-repeat center center;
  background-size: cover;
}

.shop__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;
}
.shop__wrapper a:hover img {
  transform: scale(1.2);
}
.shop__image {
  height: 350px;
  margin-bottom: 2rem;
  overflow: hidden;
}
.shop__image img {
  height: 100%;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  object-fit: contain;
  width: 100%;
}
.shop__content button {
  display: block;
}
.shop__content span.cat {
  background-color: var(--background-hover-button);
}

.shop__content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

span.number {
  position: absolute;
  top: -14px;
  right: -12px;
  border-radius: 50%;
  background: var(--background-hover-button);
  width: 15px;
  height: 15px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  padding: 10px;
  font-size: 0.8rem;
}

.cart-wrapper {
  display: grid;
  grid-gap: 3rem;
  grid-template-columns: 1fr;
}
.cart-wrapper table button {
  width: 30px;
  background: var(--background-hover-button);
  color: var(--primary-color);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
}

.single-product {
  grid-template-columns: 1fr;
  gap: 2rem;
}
.single-product .single__image {
  overflow: hidden;
}
.single-product .single__image img {
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}
.single-product .single__image:hover img {
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.section {
  padding-bottom: clamp(42px, 5.5vw, 120px);
  padding-top: clamp(42px, 5.5vw, 120px);
}

.about__inner {
  gap: clamp(21px, 3vw, 35px);
}

a {
  color: var(--dark-color);
}

.faq .highlight {
  color: var(--primary-color);
}

.client-content-inner {
  background-color: var(--background-primary-color);
}

.avatar,
.faq .highlight {
  background-color: var(--background-hover-button);
}

.scroll-up:hover {
  color: var(--background-hover-button);
}

@media only screen and (min-width: 660px) {
  .navbar-brand {
    max-width: 100px;
    display: block;
  }
  .footer .social {
    justify-content: flex-start;
  }
  .footer .copyright-text {
    margin-top: 0;
    justify-content: flex-end;
  }
  .contact .input-row {
    grid-template-columns: 1fr 1fr;
  }
  .footer-inner {
    flex-direction: row;
  }
  .client.section .client-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
  }
  .form form .input-wrap:not(:last-child) {
    margin-bottom: 0;
  }
  .todo-result {
    grid-template-columns: repeat(2, 1fr);
  }
  .contact .input-row {
    gap: 40px;
  }
  .movies .banner__content h2 {
    font-size: 3.75rem;
  }
  .single__poster {
    grid-template-columns: 30% 1fr;
  }
  .single__poster .single__content {
    margin-top: 2rem;
  }
  .banner__content .btn__link {
    width: auto;
  }
}
@media only screen and (min-width: 768px) {
  section {
    padding: 40px 0;
  }
  .stats {
    margin-top: 0;
  }
  .container,
  .header__top {
    padding-left: 50px;
    padding-right: 50px;
  }
  .client.section .client-wrapper .client-content-inner {
    margin-bottom: -140px;
  }
  .contact .input-row {
    grid-gap: 40px;
  }
  .weather__forecast ul {
    grid-template-columns: repeat(2, 1fr);
  }
  .tab .tabs {
    grid-template-columns: 20% 80%;
  }
  .tab .tabs ul {
    flex-direction: column;
    margin: 0;
    justify-content: flex-start;
  }
  .tab__lists li.active-tab:after {
    position: absolute;
    right: -21px;
    top: 0;
    width: 20px;
    height: 100%;
    content: "";
  }
  .single__content .title {
    font-size: 2rem;
  }
  .trending .card {
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
  }
  .trending .card:hover, .trending .card:focus {
    -moz-transform: scale(1.3);
    -o-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .trending .swiper {
    padding: 3rem 2rem;
  }
  .shop__wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .single-product {
    grid-template-columns: repeat(2, 1fr);
    gap: 3.5rem;
  }
  .faq .inner-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .faq .inner-container .skills {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .header__top .navbar .navbar-nav {
    flex-direction: row;
    display: flex;
    position: relative;
    transform: none;
    align-items: center;
    min-height: auto;
  }
  .header__top .navbar .custom-nav {
    min-height: auto;
    transform: none;
    position: relative;
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
  button.hamburger {
    display: none;
  }
  .header__top .navbar .navbar-nav {
    position: relative;
  }
  body:not(.scrolled) .header__top .navbar .navbar-nav > li > a {
    color: var(--color-light);
  }
  .client.section .client-wrapper .client-content-inner {
    margin-bottom: -200px;
  }
  .video {
    height: 60vh;
  }
  .header__top .navbar .navbar-nav li.has-child:hover .sub-menu {
    opacity: 1;
    visibility: visible;
    top: 100%;
    margin-top: 10px;
  }
  .header__top .navbar .navbar-nav li .sub-menu {
    position: absolute;
    top: 140%;
    width: 250px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-direction: column;
  }
  .header__top .navbar .navbar-nav li .sub-menu li {
    text-align: left;
  }
  .todo-result {
    display: grid;
    margin-top: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  .weather__forecast ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    margin-top: 3rem;
  }
  .shop__wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
  .cart-wrapper {
    grid-template-columns: 70% 1fr;
  }
  .faq .inner-container .skills {
    grid-template-columns: repeat(3, 1fr);
  }
}

