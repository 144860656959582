.section.todo {
    background: #0cebeb;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #29ffc6, #20e3b2, #0cebeb);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: relative;
}

.modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #20e3b2e0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    @include transition(0.5s ease-in-out);
    visibility: hidden;
    z-index: 99;

    &.show-modal {
        opacity: 1;
        visibility: visible;
    }
}

.todo .form-wrap {
    display: flex;
    justify-content: center;
    max-width: 500px;
    gap: 20px;
    margin: 0 auto;
    flex-wrap: wrap;

    select {
        padding: 10px;
        background-color: var(--background-primary-color);
    }
}

.form-wrap form {
    display: flex;
    justify-content: center;

    input {
        padding: 10px;
        background-color: var(--background-primary-color);
    }

}

button.btn {
    background: var(--btn-background);
    padding: 5px 15px;
    border: 1px solid transparent;

    &:hover {
        border-color: var(--btn-background);
        background-color: #20e3b2;
    }
}

.section:nth-child(odd) {

    input,
    textarea {
        background-color: var(--background-primary-color);
    }
}

.section:nth-child(even) {

    input,
    textarea {
        background-color: var(--background-secondary-color);
    }
}

.todo-result {
    display: grid;
    margin-top: 30px;
    grid-template-columns: 1fr;
    grid-gap: 30px;

    >div {
        margin-top: 10px;
        text-align: center;
        background: var(--background-secondary-color);
        position: relative;
        padding: 30px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        padding-bottom: 50px;

        .buttons {
            position: absolute;
            right: 30px;
            bottom: 20px;
        }

        .btn {
            margin-left: 2px;
        }
    }
}

.result-wrapper {
    text-align: center;
    margin-top: 30px;
}

p.input-field {
    text-align: left;
}

.completed {
    text-decoration: line-through;
    background-color: antiquewhite !important;
}

.clicked.btn {
    background-color: #20e3b2;
}