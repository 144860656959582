.about-section {
  position: relative;
  color: var(--black-color);

  &:after {
    content: "";
    background: rgba(77, 75, 75, 0.19);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.1px);
    -webkit-backdrop-filter: blur(12.1px);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }

  .inner_wrap {
    position: relative;
    z-index: 1;
  }

  h2 {
    text-shadow: 2px 2px 2px var(--shadow);
    color: #fff;
  }
}

.marque {
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-right: 10px;

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes marquee {

  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-300%);
  }
}

.text-slide {
  overflow: hidden;

}

.inner-slide {
  -moz-animation: marquee 50s linear infinite;
  -webkit-animation: marquee 50s linear infinite;
  animation: marquee 50s linear infinite;
  display: flex;
}

.about-section,
.inner-banner {
  &:after {
    content: "";
    background: rgba(77, 75, 75, 0.19);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12.1px);
    -webkit-backdrop-filter: blur(12.1px);
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }
}