.client.section {
    background: url('../assets/images/client-bg.jpg') no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    padding: clamp(120px, 8vw, 450px) 0;

    .client-wrapper {
        display: grid;
        grid-gap: 30px;
        grid-template-columns: (1fr);

        .client-title {
            font-size: 35px;
            display: flex;
            align-items: center;

            h2 {
                color: var(--primary-color);
            }
        }

        .client-content-inner {
            max-width: 400px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            padding: clamp(30px, 3vw, 40px);
            border-radius: 5px;
            margin-left: auto;
            margin-right: 0;

            h2 {
                font-size: 24px;
                margin-bottom: 21px;
                font-family: "Montserrat", sans-serif;
            }

            .author {
                display: flex;
                align-items: center;
                margin-top: 35px;
                gap: 10px;

                .avatar {
                    width: 50px;
                    height: 50px;
                    @include border(50%);
                }
            }
        }
    }
}