.section:nth-child(odd).faq .faq__accordion h3 {
  background-color: var(--background-primary-color)
}

.section:nth-child(even).faq .faq__accordion h3 {
  background-color: var(--background-secondary-color);
  ;

}

.faq {
  .sub-title {
    margin-bottom: clamp(21px, 3vw, 50px);

    h2 {
      margin-bottom: 0;
    }
  }

  .highlight {
    padding: 0 10px;
  }
}

.answer {
  height: 0;
  overflow: hidden;
  @include transition(height 0.5s ease-in-out);
}

.answer.active {
  @include transition(height 0.5s ease-in-out);
  padding: 15px;
  height: auto;
}

.faq__accordion {
  h3 {
    padding: 20px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  p {
    border: 1px solid var(--border-color);
  }
}

.skills {
  display: grid;
  flex: 1;
  grid-template-columns: 1fr;
  gap: 5px;

  li {
    padding: 0.5rem 1rem;
    border: 1px solid transparent;
    text-align: center;
    border-radius: 0.7rem;
    background: var(--dark-color);
    display: flex;
    gap: 1rem;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    @include transition(0.5s ease-in-out);

    .title,
    svg {
      color: var(--color-light);
    }

    &:hover {
      flex: 5;
      border-color: var(--border-color);
      @include transform(scale(1.1));
      background: var(--background-secondary-color);

      .title,
      svg {
        color: var(--dark-color);
      }

    }

    h2 {
      font-size: 0.8rem;
      font-family: "Montserrat", sans-serif;
    }
  }
}

.faq .inner-container {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr;

}