    :root {
        --primary-color: #fff;

        --secondary-color: #06b7ff;

        --black-color: #000;

        --dark-color: #333;

        --color-light: #fff;

        --btn-background: #fff;

        --shadow: rgba(0, 0, 0, 0.2);

        // color: map-get(--theme-color, theme-dark-color); // #000

        --background-primary-color: #fff;

        --background-secondary-color: #f8f8f8;

        --background-dark-color: #333;

        --background-button: #000;

        --button-color: #fff;

        --background-hover-button: #05a394;
        --border-color: #e6e6e6;

    }

    .dark {
        --primary-color: #fff;

        --secondary-color: #06b7ff;

        --color-light: #000;

        --black-color: #fff;

        --dark-color: #fff;

        --btn-background: #3d3d3d;

        // color: map-get(--theme-color, theme-dark-color); // #000

        --background-primary-color: #000;

        --background-secondary-color: #141414;

        --background-dark-color: #020202;

        --background-button: #fff;

        --button-color: #000;

        --background-hover-button: #05a394;
        --border-color: #2e2d2d;
    }