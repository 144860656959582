section.movies {
    padding-top: 0;
}

.movies {

    .banner {
        background: url('https://image.tmdb.org/t/p/w1920_and_h600_multi_faces/rGbRnRvkmrSub07ty89Vnlsh6UX.jpg') no-repeat center center;
        min-height: 250px;
        background-size: cover;
        padding: 2rem;
        position: relative;


        &::after {
            content: "";
            background: rgba(45, 28, 111, 0.5);
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }

        &__content {
            color: var(--primary-color);
            max-width: 450px;
            position: relative;
            z-index: 1;

            h2 {
                font-family: 'Inter', sans-serif;
                font-size: 2.75rem;
                text-transform: uppercase;
                line-height: 1.1;
                font-weight: 700;
                display: inline-block;
                border-bottom: 2px solid var(--border-color);
                padding-bottom: 0.5rem;
                margin-bottom: 0.5rem;
            }
        }
    }
}

.banner__content {
    .btn__link {
        display: inline-block;
        border: 2px solid var(--border-color);
        @include border(45px);
        width: 100%;

        &:hover,
        &:focus {
            border-color: transparent;
        }
    }
}

// TRENDING

.trending {
    margin-top: 3rem;

    .swiper {
        background-image: url(../assets/images/chart.svg);
        background-position: 0% 90px;
        background-repeat: no-repeat;

        .card {
            &__image {
                margin-bottom: 1rem;
            }
        }

        img {
            @include border(10px);
        }

        .title {
            font-weight: 700;
        }

        .card__date {
            font-size: 0.8rem;
        }
    }
}

// Single Detail

.single {
    .banner {
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;

        .back {
            position: relative;
            z-index: 2;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            gap: 0.7rem;
        }

        &::after {
            content: "";
            background: rgba(45, 28, 111, 0.8);
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }

    }

    &__poster {
        display: grid;
        grid-template-columns: 1fr;
        padding: 2rem 0;
        position: relative;
        z-index: 3;
        grid-gap: 2rem;

        .poster {
            position: relative;
        }

        .status {
            padding: 0.2rem 0.8rem;
            background-color: var(--background-hover-button);
            color: var(--primary-color);
            font-style: italic;
            width: auto;
            display: inline-block;
            @include border(45px);
            position: absolute;
            top: 1.25rem;
            left: 1.25rem;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }

        img {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
    }

    &__content {

        .title {
            font-size: 1.35rem;
            font-weight: 700;

            h2 {
                font-family: 'Inter', sans-serif;
            }

            .lang {
                text-transform: uppercase;
            }
        }

        .popularity,
        .duration {
            margin: 1rem 0;
            font-size: 0.8rem;
            @include flex;
            gap: 0.5rem;
            align-items: center;
        }

        .tagline {
            font-style: italic;
            font-size: 0.8rem;
            margin-bottom: 1rem;
        }

        .overview {
            font-family: 'Montserrat', sans-serif;
            font-weight: 400;

            strong {
                margin-bottom: 0.5rem;
                display: block;
                font-size: 1.6rem;
            }
        }
    }
}

// PROFILE

.profile {
    margin: 2rem 0 3rem;
    color: var(--primary-color);
    text-align: center;

    &__img {
        width: 150px;
        height: 150px;
        margin: 0 auto;
        margin-bottom: 0.8rem;

        img {
            @include border(50%);
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
    }
}

.search-movie {
    margin-top: 1rem;
    @include flex;
    align-items: center;
    position: relative;
    z-index: 1;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 1rem;

    input {
        width: 100%;
        @include border(45px 0 0 45px);
    }

    .search-btn {
        @include border(0 45px 45px 0);
        margin-top: 0;
    }
}