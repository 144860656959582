@include media(mobile) {
  .navbar-brand {
    max-width: 100px;
    display: block;
  }

  .footer {
    .social {
      justify-content: flex-start;
    }

    .copyright-text {
      margin-top: 0;
      justify-content: flex-end;
    }
  }

  .contact .input-row {
    grid-template-columns: 1fr 1fr;
  }

  .footer-inner {
    flex-direction: row;
  }

  .client.section .client-wrapper {
    grid-template-columns: 1fr 1fr;
    grid-gap: 60px;
  }

  .form form .input-wrap:not(:last-child) {
    margin-bottom: 0;
  }

  .todo-result {
    grid-template-columns: repeat(2, 1fr);
  }

  .contact .input-row {
    gap: 40px;
  }

  .movies .banner__content h2 {
    font-size: 3.75rem;
  }

  .single__poster {
    grid-template-columns: 30% 1fr;

    .single__content {
      margin-top: 2rem;
    }
  }

  .banner__content .btn__link {
    width: auto;
  }

}