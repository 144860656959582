.portfolio {
  .portfolio__content {
    ul {

      li {
        overflow: hidden;
        padding: 10px;
        position: relative;

        .caption {
          background-color: var(--background-primary-color);
          font-family: 'Ibarra Real Nova', serif;
          display: block;
          font-size: 26px;
          padding: 15px;
          width: 100%;
          position: relative;
          z-index: 99;
        }

        .project {
          display: block;


          &:hover {
            img {
              transform: scale(1.1);
            }

            .caption {
              background-color: var(--background-hover-button);
              color: var(--color-light);
            }
          }

          img {
            transition: 0.3s ease-in-out;
            width: 100%;
          }
        }


      }
    }
  }
}