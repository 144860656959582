.footer {
  padding: 20px 0;
  background-color: var(--background-primary-color);

  .social {
    margin-bottom: 0;
    display: flex;
    justify-content: center;

    li:not(:last-child) {
      margin-right: 10px;
    }
  }

  .copyright-text {
    display: flex;
    margin-top: 20px;
    justify-content: center;

    p {
      margin-bottom: 0;
    }
  }
}

.footer-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  p {
    margin: 0;
  }
}