section.contact {
  padding-top: 0;
}

.contact {
  input:invalid {
    background-color: ivory;
    border: 2px solid red;
    border-radius: 5px;
  }

  .section__heading {
    text-align: left;
  }

  .contact-form {
    max-width: 700px;
    margin: 0 auto;
    margin-top: 30px;
  }

  .contact-map {
    height: clamp(450px, 3vw, 500px);
  }

  &__image {
    display: flex;
    justify-content: center;
    height: 100%;

    img {
      width: 60%;
    }
  }

  &__title {
    margin-bottom: 30px;
  }

  label {
    width: 100%;
    margin-bottom: 15px;
  }

  .input-group {
    margin-bottom: 20px;
  }

  input,
  textarea {
    padding: 15px;
    width: 100%;
    border: 0;
    border: 2px solid var(--border-color);
    background-color: transparent !important;

    &:focus {
      border-color: var(--background-hover-button);
      outline: none;
    }
  }

  textarea {
    min-height: 200px;
    margin-bottom: 0;
  }

  .input-row {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.contact .inner-container {
  grid-template-columns: 1fr;
  display: grid;
}