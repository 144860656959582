* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  background-color: var(--background-primary-color);
  color: var(--dark-color);

}

.entry-content {
  margin-bottom: 21px;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
}

.container,
.header__top {
  padding-left: 30px;
  padding-right: 30px;
}

button,
input[type="submit"] {
  cursor: pointer;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: #05a394;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

.section:nth-child(odd) {
  background-color: var(--background-secondary-color);
}

.btn__link,
.caption {
  transition: 0.3s ease-in-out;
}

.section__heading {
  padding-bottom: 28px;

  h2 {
    font-size: $huge;
  }
}

.stats {
  margin-top: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Ibarra Real Nova', serif;
  color: var(--dark-color);
  margin-top: 0;
  font-weight: 700;
}

.faq {
  .section__heading {
    text-align: left;
  }
}

.cursor {
  position: fixed;
  top: -50%;
  left: -50%;
  width: 76px;
  height: 76px;
  z-index: 999999;
  pointer-events: none;
  border-radius: 50%;
  transform-origin: left top;
  transition: 0.2s;
  transform: scale(0.35) translate(-50%, -50%);
  background-color: #fff;
  mix-blend-mode: difference;
}

.input-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.form {
  form {
    max-width: 850px;
    margin: 0 auto;

    .input-wrap:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

input,
textarea {
  padding: $btn-vertical-spacing $btn-horizontal-spacing;
  color: var(--dark-color);
  outline: none;
}

.time.btn__link {
  margin-top: 0;
  text-align: center;
  border-radius: 0;
  padding: 2px 14px;
  min-width: 100px;
}

.scroll-up {
  position: fixed;
  right: 50px;
  cursor: pointer;
  bottom: 50px;
  transform: scale(4);
  z-index: 99999;
  opacity: 0;
  @include transition(0.5s ease-in-out);

  &:hover {
    @include transform(scale(2.5) !important);
  }
}

.input-group {
  position: relative;

  .error {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.search-input {
  max-width: 450px;
  margin: 0 auto;
  margin-bottom: 40px;

  input {
    padding: 15px;
    width: 100%;
  }
}

.toggle {
  position: relative;
  box-sizing: border-box;
  width: 75px;
  margin: 0 auto;
  border: 1px solid #fff;
  border-radius: 25px;
  height: 40px;
  overflow: hidden;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    background-color: #fff;

    &:checked {
      background-color: #000;

      + {
        label {
          background-color: #fff;

          &:before {

            // box-shadow: inset 30px 0 0 #000;
            background: url(../assets/images/moon.png) no-repeat center center;
            background-size: contain;
            @include transform(translateX(130%));
          }
        }
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    @include transition(0.5s ease-in-out);

    &:before {
      background: url(../assets/images/sun.png) no-repeat center center;
      content: '';
      width: 30px;
      left: 0;
      height: 30px;
      // box-shadow: inset -30px 0 0 #fff;
      position: absolute;
      display: inline-block;
      border-radius: 20px;
      box-sizing: border-box;
      transition: 0.25s ease-in-out;
      background-size: contain;
      @include transform(translateX(0));

    }
  }
}