.video {
    height: 37vh;
    position: relative;

    &__wrapper {
        height: 100%;
    }

    video {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__wrapper-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.4);
        color: #fff;

        .button {
            font-size: 3rem;
            cursor: pointer;
            height: 100px;
            border-radius: 50%;
            border: 2px solid #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100px;
        }
    }
}