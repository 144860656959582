.section:nth-child(odd) {

    .tab__lists li.active-tab,
    .tabs .content,
    .tab__lists li.active-tab:after {
        background-color: var(--background-primary-color);
    }
}

.section:nth-child(even) {

    .tab li.active-tab,
    .tabs .content,
    .tab__lists li.active-tab:after {
        background-color: var(--background-secondary-color);
    }
}

.tab {
    &__lists {
        max-width: 500px;
        display: flex;
        gap: 20px;
        margin: 0 auto;
        flex-wrap: wrap;
        justify-content: center;

        li {
            padding: 0.8rem 1rem;
            cursor: pointer;
            border: 1px solid #000;
            position: relative;

            &.active-tab {
                border-color: transparent;
            }
        }
    }

    .tabs {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .content {
        padding: 20px;
        background: #fff;

        .title {
            margin: 1rem 0;
            font-weight: 700;
        }

        ul li:not(:last-child) {
            margin-bottom: 1.3rem;
        }
    }

}