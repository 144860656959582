@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ibarra+Real+Nova:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@import '../css/scss/mixin';
@import '../css/scss/Variables/variables';
@import '../css/scss/Element';
@import '../css/scss/Header/HeaderStyled';
@import '../css/scss/pages/Client/clientStyled.scss';
@import '../css/scss/pages/Tab/tab';
@import '../css/scss/pages/Video/videoStyled.scss';
@import '../css/scss/pages/Movie/movie';
@import '../css/scss/pages/Faq/FaqStyled';
@import '../css/scss/pages/Portfolio/PortfolioStyled';
@import '../css/scss/pages/Weather/weatherStyled';
@import '../css/scss/pages/Contact/contactStyled';
@import '../css/scss/pages/Form/form';
@import '../css/scss/about';
@import '../css/scss/Footer/footerStyled';
@import '../css/scss/Variables/colors';
@import '../css/scss/btn';
@import '../css/scss/shop';
@import '../css/scss/gap';
@import '../css/scss/color';
@import '../css/scss/MediaQueries/media';