.weather {
    .search {
        text-align: center;
        max-width: 450px;
        margin: 0 auto;

        input {
            width: 100%;
        }
    }

    &__forecast {
        .country {
            font-size: 3rem;
            text-align: center;

            .country-title {
                font-size: 1.5rem;
            }
        }

        ul {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 30px;
            margin-top: 3rem;

            h2 {
                font-size: 3rem;
            }

            li {
                background: var(--background-primary-color);
                padding: 20px;
                text-align: center;

                img {
                    margin: 0 auto;
                }

                .weather-data {
                    display: flex;
                    margin-top: 1rem;
                    justify-content: center;
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }

    }
}

.snow.section {
    background: url(../assets/images/mist.jpg) no-repeat center center;
    background-size: cover;
}

.Sunny.section {
    background: url(../assets/images/sunny.jpg) no-repeat center center;
    background-size: cover;
}

.Mist.section {
    background: url(../assets/images/snow.jpg) no-repeat center center;
    background-size: cover;
}

.wind {
    background-image: url(../assets/images/wind.gif);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: 50px 50px;
}

.temp {
    @extend .wind;
    background-image: url(../assets/images/temp.gif);

}

.specs {
    position: relative;
}

.condition span.img {
    position: absolute;
    top: 0;
}