.btn-wrap {
  margin-top: clamp(21px, 3vw, 35px);
}

.btn__link {
  background-color: var(--background-button);
  color: var(--button-color);
  border-radius: 45px;
  text-transform: uppercase;
  border: 0;
  padding: $btn-vertical-spacing $btn-horizontal-spacing;
  font-size: 0.8rem;

  &:hover,
  &:focus {
    background-color: var(--background-hover-button);
    color: var(--button-color);
    box-shadow: 0 16px 29px -17px #727272;
  }
}