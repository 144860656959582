.header__top {
  .navbar {
    left: 0;
    width: 100%;
    z-index: 1;

    h1 {
      font-size: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      position: relative;
      z-index: 9999;

      .logo {
        max-width: 40px;
      }
    }

    .navbar-nav {
      display: flex;
      gap: 10px;
      flex-direction: column;

      li {
        position: relative;

        .sub-menu {
          top: 100%;
          @include transition(0.5s ease-in-out);
          left: 0;
          width: 100%;
          background-color: var(--background-secondary-color);
          display: none;


          li {
            padding: 15px;
            text-align: center;

            &:not(:last-child) {
              border-bottom: 1px solid rgba(169, 169, 169, 0.3);
            }
          }
        }
      }



      a {
        font-size: 14px;
        color: var(--dark-color);
        text-transform: uppercase;
        position: relative;
        transition: 0.5s ease-in-out;
        padding: 10px 5px;

        &.active {
          color: var(--background-hover-button);
        }

        &:hover,
        &:focus {
          color: var(--background-hover-button);
        }
      }
    }
  }
}

body:not(.scrolled) {

  .header__top .navbar .navbar-nav>li>a,
  .navbar:has(.custom-nav.active) .hamburger {
    color: var(--dark-color);
  }
}

body:not(.scrolled) {

  .site-branding a,
  .hamburger {
    color: var(--color-light);
  }
}

body.scrolled {
  .site-branding a {
    color: var(--dark-color);
  }

  &.dark {
    .site-branding a {
      color: var(--dark-color);
    }
  }
}

body.dark:not(.scrolled) {

  .header__top .navbar .navbar-nav>li>a,
  .site-branding a {
    color: var(--dark-color);
  }
}

.site-branding {
  z-index: 0;
}

.custom-nav {
  display: flex;

  position: fixed;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  padding: 30px;
  padding-bottom: 50px;
  padding-top: 50px;
  background: var(--background-secondary-color);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  transition: 0.5s ease-in-out;
  transform: translateX(-100%) translateY(0);

  &.active {
    transform: translateX(0) translateY(0);
  }
}

header {
  border-bottom: 1px solid var(--border-color);
  padding: 20px 0;
  @include transition(0.3s ease-in-out);
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}

.scrolled header {
  background-color: var(--background-primary-color);
}

.header__top .navbar .navbar-nav {
  width: 100%;
  z-index: 999;
  justify-content: center;
  align-items: center;
  gap: 30px;
  min-height: 80vh;
  overflow: visible;
  scrollbar-width: none;
}

.navbar .inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.hamburger {
  display: block;
}

button.hamburger {
  background: transparent;
  padding: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  position: relative;
  z-index: 9999;

  svg {
    font-size: 20px;
  }
}

.header__top .navbar .navbar-nav li.has-child {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-nav ul:not(.sub-menu) {
  li a {
    &:before {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      content: "";
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      width: 3px;
      height: 3px;
      background-color: var(--background-hover-button);
    }

    &:hover:before {
      opacity: 1;
    }

    &.active {
      &:before {
        opacity: 1;
      }
    }
  }
}

.custom-nav .navbar-nav ul.sub-menu li a {
  &.active &:before {
    content: '';
  }

  &:hover {
    &::before {
      content: none;
    }
  }
}

.custom-nav .navbar-nav ul.sub-menu li a.active {
  &:before {
    content: none;
  }
}

.header__top .navbar .navbar-nav li .sub-menu.show-sub {
  display: block;
  opacity: 1;
  visibility: visible;
}

.about__content {
  color: var(--primary-color);
}

.header__top .navbar .custom-nav {
  min-height: 100vh;
}

body:has(.custom-nav.active) {
  height: 100vh;
  overflow: hidden;
}